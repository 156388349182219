<template>
    <el-dialog :visible.sync="dialogData.visible" :title="dialogData.title" @close="onDialogClose" :destroy-on-close="true">
        <el-col class="el-col-sm-24 el-col-md-24 el-col-lg-24" style="margin-bottom: 12px">
            <el-col class="el-col-sm-24 el-col-md-10 el-col-lg-6">
                <el-col class="el-col-sm-6 el-col-md-6 el-col-lg-7" style="margin-top: 12px">文章ID</el-col>
                <el-col class="el-col-sm-17 el-col-md-17 el-col-lg-16">
                    <el-input v-model="searchCondition.id" placeholder="请输入文章ID"></el-input>
                </el-col>
            </el-col>

            <el-col class="el-col-sm-24 el-col-md-10 el-col-lg-6">
                <el-col class="el-col-sm-5 el-col-md-5 el-col-lg-6" style="margin-top: 12px">标题</el-col>
                <el-col class="el-col-sm-19 el-col-md-19 el-col-lg-17">
                    <el-input v-model="searchCondition.title" placeholder="请输入文章标题"></el-input>
                </el-col>
            </el-col>

            <el-col class="el-col-sm-24 el-col-md-10 el-col-lg-6">
                <el-col class="el-col-sm-5 el-col-md-5 el-col-lg-6" style="margin-top: 12px">摘要</el-col>
                <el-col class="el-col-sm-19 el-col-md-19 el-col-lg-17">
                    <el-input v-model="searchCondition.excerpt" placeholder="请输入文章摘要"></el-input>
                </el-col>
            </el-col>

            <el-col class="el-col-sm-24 el-col-md-24 el-col-lg-6">
                <el-button type="primary" @click="onSearchClick">搜索</el-button>
                <el-button @click="onResetSearchClick">重置</el-button>
            </el-col>
        </el-col>

        <el-table
                height="500px"
                v-el-table-infinite-scroll="loadMoreQuestionData"
                :data="articleList"
                v-loading="loadingStatus"
                @selection-change="getSelectList"
                ref="elTable"
        >
            <el-table-column
                    type="selection"
                    width="55"
            ></el-table-column>
            <el-table-column v-for="(item,index) in articleColumns"
                             :key="index"
                             :prop="item.value"
                             :label="item.title"
                             :width="item.width">
                <template slot-scope="scope">
                        <span v-if="item.type == 'image'"><img :src="scope.row[item.url]" :alt="scope.row[item.value]"
                                                               width="40px"></span>
                    <span v-else v-html="scope.row[item.value]"></span>
                </template>
            </el-table-column>
        </el-table>

        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose">取 消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    const NO_DEL = 'no_del';
    const DEL = 'del';
    import elTableInfiniteScroll from 'el-table-infinite-scroll';

    export default {
        name: "ArticlePickDialog",
        directives: {
            'el-table-infinite-scroll': elTableInfiniteScroll
        },
        props: {
            dialogData: {
                type: Object,
                default: function () {
                    return {
                        // visible: false,
                        // title: "dialog",
                        // width: '50%'
                    }
                }
            },


            articleDialogData: {
                type: Object,
                default: () => {
                    return id
                }
            }
        },

        data() {
            return {
                //搜索条件
                searchCondition: {
                    excerpt: "",
                    title: ""
                },
                //文章列表
                articleList: [],
                loadingStatus: false,

                selectArticleList: [],
                selectArticleId: [],

                articleColumns: [
                    {
                        title: "标题",
                        value: 'title'
                    },
                    {
                        title: "封面",
                        type: "image",
                        value: 'cover_img',
                        url: "cover_pic_url"
                    },
                    {
                        title: "摘要",
                        value: 'excerpt',
                    },
                ],

                totalCount: null,

                // dialogData:{
                //     visible: false,
                //     title: "dialog",
                //     width: '50%'
                // },

                //分页信息
                pagingInfo: {
                    page_size: 10,
                    current_page: 1
                },
            }
        },

        mounted() {
        },

        methods: {
            //搜索
            onSearchClick() {
                this.pagingInfo.current_page = 1;
                this.searchParams = {};
                if (this.searchCondition && this.searchCondition.hasOwnProperty('title') && this.searchCondition["title"] !== "") {
                    this.searchParams["title"] = this.searchCondition["title"];
                }

                if (this.searchCondition && this.searchCondition.hasOwnProperty('excerpt') && this.searchCondition["excerpt"] !== "") {
                    this.searchParams["excerpt"] = this.searchCondition["excerpt"];
                }

                if (this.searchCondition && this.searchCondition.hasOwnProperty('id') && this.searchCondition["id"] !== "") {
                    this.searchParams["id"] = this.searchCondition["id"];
                }

                this.getArticleListPool(DEL);
            },

            //重置
            onResetSearchClick() {
                this.searchCondition = {
                    excerpt: "",
                    title: "",
                    id: ""
                };
                this.searchParams = {};
                this.getArticleListPool(DEL);
            },

            //弹框关闭
            onDialogClose() {
                this.searchCondition = {
                    excerpt: "",
                    title: "",
                    id: ""
                };

                this.searchParams = {};

            },

            //加载
            loadMoreQuestionData() {
                if (this.loadingStatus) {
                    return;
                }

                if (this.totalCount == null) {
                    return;
                }

                if (this.articleList.length >= this.totalCount) {
                    // this.$message("已经到底了");
                    return;
                }

                this.$message.success('加载下一页');
                this.pagingInfo.current_page++;
                this.getArticleListPool(NO_DEL);
            },

            getSelectList(list) {
                this.selectArticleId = [];
                for (let article of list) {
                    let object = {
                        id: article.id
                    };
                    this.selectArticleId.push(object);
                }

            },

            //弹框取消
            dialogClose() {
                // this.dialogData.visible = false;
                this.$emit('dialog-close')
            },

            async getArticleListPool(type) {
                this.loadingStatus = true;
                let inputData = {};

                for (let param in this.searchParams) {
                    inputData[param] = this.searchParams[param];
                }

                if (this.articleDialogData.id) {
                    inputData.sg_id = this.articleDialogData.id;
                }

                inputData.current_page = this.pagingInfo.current_page;
                inputData.page_size = this.pagingInfo.page_size;

                let {list, pages} = await this.$api.poolStudyGuide(inputData);

                this.totalCount = pages.total;

                if (list.length < 0) {
                    this.$message.error("文章为空,请先添加文章");
                    this.loadingStatus = false;
                    return;
                }

                this.$nextTick(() => {
                    if (type === DEL) {
                        this.articleList = list;
                    } else {
                        for (let article of list) {
                            this.articleList.push(article);
                        }
                    }

                    if (this.articleList.length >= this.totalCount) {
                        this.$message.warning("已经到底了");
                    }

                    this.loadingStatus = false
                });
            },

            //弹框确定
            handleConfirm() {
                if (this.selectArticleId.length <= 0) {
                    this.$message("请选择要添加的文章");
                    return;
                }

                this.$emit('dialog-confirm', this.selectArticleId);
            },

            resetData() {
                this.selectArticleList = [];
                this.selectArticleId = [];
                this.searchParams = {};
                this.getArticleListPool(DEL);
            }
        }
    }
</script>

<style scoped>

</style>